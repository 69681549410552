<template>
  <div
    class="field"
    :class="{ 'required': settings.isRequired }"
  >
    <label>Address</label>
    <slide-up-down
      :active="showSingleLineAddress"
      :duration="750"
    >
      <div class="ui input d-flex align-items-center">
        <input
          id="address"
          v-model="address"
          :class="settings.class"
          :placeholder="settings.placeHolderText"
          type="text"
          autocomplete="chrome-off"
          @change="resetValidation"
          @blur="validate"
        >
      </div>
      <span
        v-if="enableSingleLineAddress"
        class="text-nowrap px-2"
      >Address not found? <a
        href="javascript:;"
        @click="toggleLineAddress"
      >click here</a></span>
      <div
        id="results"
        class="pac-container"
      />
      <div class="ui input d-flex align-items-center">
        <span
          v-show="!store.state.canProceed"
          class="alert alert-danger"
          role="alert"
        >Please ensure that you have selected the suggested place from Google suggestions</span>
      </div>
      <!-- .field -->
    </slide-up-down>
    <slide-up-down
      :active="!showSingleLineAddress"
      :duration="750"
    >
      <address-fields
        v-show="!showSingleLineAddress"
        :settings="fieldSettings"
        @addressLine1Changed="onAddressLine1Changed"
        @addressLine2Changed="onAddressLine2Changed"
        @suburbChanged="onSuburbChanged"
        @postcodeChanged="onPostcodeChanged"
        @stateChanged="onStateChanged"
        @countryChanged="onCountryChanged"
      />

      <a
        v-if="enableSingleLineAddress"
        id="lnkToggleView"
        href="javascript:;"
        @click="toggleLineAddress"
      >Back to single line address search</a>
    </slide-up-down>
    <component
      :is="getComponent()"
      :settings="settings"
      :input-address="address"
      @addressSelected="onAddressSelected"
      @loadError="onLoadError"
    />
  </div>
</template>

<script>
import ModuleBase from "../Common/ModuleBase.vue";
import SlideUpDown from "vue-slide-up-down";
import componentLookup from "../componentLookup";
import GoogleLookup from "./Lookup/Google/GoogleLookup.vue";
import AddressFields from "../Address/AddressFields.vue";

export default {
  name: "Address",
  components: {
    SlideUpDown,
    GoogleLookup,
    AddressFields
  },
  mixins: [ModuleBase],
  props:{

  },
  data() {
    return {
      showSingleLineAddress: true,
      address: '',
      fieldSettings:this.settings,
      showError:false,
      enableSingleLineAddress: true
    }
  },
  watch: {
    enableSingleLineAddress(val) {
        if (val === false) {
            this.showSingleLineAddress = false;
        }
    }
  },
  mounted(){
     this.address = this.formatAddress(this.settings.fields)
   },
   validators: {

	},
  methods: {
    $ModuleBase_validate() {
        return this.isValid;
    },

    onLoadError() {
        this.enableSingleLineAddress = false;
    },

    formatAddress(val){
      var address = '';

      if(val.addressLine1.value && val.addressLine1.value !== '')
        address += val.addressLine1.value + ' ';

      if(val.addressLine2.value && val.addressLine2.value !== '')
        address += val.addressLine2.value + ' ';

      if(val.suburb.value && val.suburb.value !== '')
        address += val.suburb.value + ', ';

      if(val.state.value && val.state.value !== '')
        address += val.state.value + ' ';

      if(val.postcode.value && val.postcode.value !== '')
        address += val.postcode.value + ', ';

      if(val.country.value && val.country.value !== '')
        address += val.country.value;

      return address;
    },
    getComponent: function () {
      return componentLookup.identifier[this.settings['lookupType']];
    },
    toggleLineAddress() {
      this.showSingleLineAddress = !this.showSingleLineAddress;
    },
    onAddressSelected(val){
      this.fieldSettings.fields.addressLine1.value = val.addressLine1
      this.fieldSettings.fields.addressLine2.value = val.addressLine2
      this.fieldSettings.fields.suburb.value = val.suburb
      this.fieldSettings.fields.postcode.value = val.postcode
      this.fieldSettings.fields.country.value = val.country
      this.fieldSettings.fields.state.value = val.state

      this.address = this.formatAddress(this.fieldSettings.fields);
      if (this.fieldSettings.expandOnSelect == undefined || this.fieldSettings.expandOnSelect === true)
        this.showSingleLineAddress = false;

      this.isValid = true;

      var addressFields = {
        address: this.address,
        addressLine1: val.addressLine1,
        addressLine2: val.addressLine2,
        suburb: val.suburb,
        postcode: val.postcode,
        country: val.country,
        state: val.state
      };
      this.$emit('addressChanged', addressFields);
    },

    getAddressFields() {
      var addressFields = {
        address: this.address,
        addressLine1: this.fieldSettings.fields.addressLine1.value,
        addressLine2: this.fieldSettings.fields.addressLine2.value,
        suburb: this.fieldSettings.fields.suburb.value,
        postcode: this.fieldSettings.fields.postcode.value,
        country: this.fieldSettings.fields.country.value,
        state: this.fieldSettings.fields.state.value
      };

      return addressFields;
    },

    onAddressLine1Changed(val) {
      this.fieldSettings.fields.addressLine1.value = val;
      var addressFields = this.getAddressFields();
      this.$emit('addressChanged', addressFields);
    },

    onAddressLine2Changed(val) {
      this.fieldSettings.fields.addressLine2.value = val;
      var addressFields = this.getAddressFields();
      this.$emit('addressChanged', addressFields);
    },

    onSuburbChanged(val) {
      this.fieldSettings.fields.suburb.value = val;
      var addressFields = this.getAddressFields();
      this.$emit('addressChanged', addressFields);
    },

    onPostcodeChanged(val) {
      this.fieldSettings.fields.postcode.value = val;
      var addressFields = this.getAddressFields();
      this.$emit('addressChanged', addressFields);
    },

    onStateChanged(val) {
      this.fieldSettings.fields.state.value = val;
      var addressFields = this.getAddressFields();
      this.$emit('addressChanged', addressFields);
    },

    onCountryChanged(val) {
      this.fieldSettings.fields.country.value = val;
      var addressFields = this.getAddressFields();
      this.$emit('addressChanged', addressFields);
    },

    resetValidation(){
      this.isValid = false;
    },

    validate(){
      this.showError = !this.store.state.canProceed;
      var addressFields = this.getAddressFields();
      this.$emit('addressChanged', addressFields);
    }
  }
}
</script>
